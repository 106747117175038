import DeviceDataService from '@/services/DeviceDataService';

const UserModule = {
  namespaced: true,

  state: () => ({
    tags: [],
    notes: [],
    id: null,
    device: null,
    loading: false,
  }),

  mutations: {
    setLoading(state, value) {
      state.loading = value
    },
    setDevice(state, data) {
      state.device = data;
    }
  },

  getters: {

  },

  actions: {
    getDevice(context, id) {
      context.commit('setLoading', true)
      DeviceDataService.get(id)
      .then((response) => {
        context.commit('setDevice', response.data)
        // this.currentDevice = response.data;
        // this.currentDevice.tags = this.currentDevice.tags.map((x) => {
        //   return {text: x};
        // });
      })
      .finally(() => {
        context.commit('setLoading', false)
      });
    },
  }
}

export default UserModule
