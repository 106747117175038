import http from "@/helpers/http-common";

class DeviceDataService {
  getAll() {
    return http.get("/devices");
  }

  getTags() {
    return http.get("/devices/tags");
  }

  get(id) {
    return http.get(`/devices/${id}`);
  }

  update(device) {
    return http.put(`/devices/${device.id}`, device);
  }

  updateTag(id, tags) {
    return http.put(`/devices/${id}`, {
      tags: tags
    });
  }

  findByDevId(dev_id) {
    return http.get(`/devices?dev_id=${dev_id}`);
  }

  delete(id) {
    return http.delete(`/devices/${id}`);
  }

  deleteMany(ids) {
    return http.delete(`/devices/many`, {
      data: ids,
    });
  }
  checkUidExists(Uid)
  {
    return http.get(`/devices/check-xid-exists/xid?xid=`+ Uid);
  }
}

export default new DeviceDataService()
